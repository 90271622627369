import { MessageDataTypeEnum } from 'constants/chatConstants';
import { UseQueryOptions, useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { GetUnreadCountResponse, Message, MessageTypeEnum } from 'talkplus-sdk';

import { useTalkPlus } from 'hooks/chat/useTalkPlus';
import { useQueryParams } from 'hooks/common/useQueryParams';

import { selectAccount } from 'features/redux/selectors/accounts';

import { ChannelListTabType } from 'types/chat/internal';
import {
  AddMembersPayload,
  BanChannelMembersPayload,
  BanMessagePayload,
  ChatPlatformReturnType,
  CreateChannelPayload,
  CreateUserPayload,
  DeleteChannelPayload,
  FreezeChannelPayload,
  GetChannelMembersPayload,
  GetChannelPayload,
  GetChannelsPayload,
  GetFileMessagesPayload,
  GetMessageUnreadCountPayload,
  GetMessagesPayload,
  GetUnreadCountPayload,
  JoinChannelPayload,
  LeaveChannelPayload,
  MarkAsReadPayload,
  RemoveMembersPayload,
  SendMessagesPayload,
  UnBanChannelMembersPayload,
  UnBanMessagePayload,
  UpdateChannelPayload,
} from 'types/chat/remote';

export const chatKeys = {
  all: ['chat'] as const,
  channels: (unreadCheck?: boolean | null) => ['channels', unreadCheck] as const,
  channel: (channelId: string) => ['channel', channelId] as const,
  messages: (channelId: string) => [...chatKeys.channel(channelId), 'messages'] as const,
  notice: (channelId: string) => [...chatKeys.messages(channelId), 'notice'],
  fileMessages: (channelId: string, messageId: string) =>
    [...chatKeys.channel(channelId), messageId, 'fileMessages'] as const,
  members: (channelId: string) => [...chatKeys.channel(channelId), 'members'] as const,
  unreads: (payload: GetUnreadCountPayload) => ['unreads', payload],
  unread: (channelId?: string, messageId?: string) => ['unreads', channelId, messageId],
};

export const useChat = () => {
  const account = useSelector(selectAccount);
  const chat: ChatPlatformReturnType = useTalkPlus();

  const useCreateUser = () => {
    return useMutation({
      mutationFn: (payload: CreateUserPayload) => chat.createUser(payload),
      onSuccess(data) {},
      onError() {},
    });
  };

  const useGetChannels = (payload: GetChannelsPayload) => {
    const query = useQueryParams();
    const tab = query.get('tab') as ChannelListTabType;
    const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery({
      queryKey: chatKeys.channels(payload.hasUnread),
      queryFn: ({ pageParam }) => {
        return chat.getChannels({
          limit: 6,
          lastChannelId: pageParam,
          isFrozen: false,
          ...payload,
        });
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.hasNext) {
          return lastPage.channels?.at(-1)?.id;
        }
      },
      select(data) {
        return {
          ...data,
          pages: data.pages.map((page) => ({
            ...page,
            channels: page.channels?.filter((channel) => {
              const categoryCondition = (() => {
                switch (tab) {
                  case 'campaign':
                    return channel.category === '1' || channel.category === '2';
                  case 'group':
                    return channel.category === '4';
                  case 'private':
                    return channel.category === '3';
                  default:
                    return true;
                }
              })();

              const searchCondition = (() => {
                if (tab === 'group') {
                  return channel.members?.some((member) => member.username?.includes(query.get('search') ?? ''));
                }

                return channel.members
                  ?.filter((member) => member.username !== account.userInfo.user.nickname)
                  .some((chatMember) => chatMember.username?.includes(query.get('search') ?? ''));
              })();

              return searchCondition && categoryCondition;
            }),
          })),
        };
      },
      onSuccess() {},
      onError() {},
      cacheTime: 0,
    });

    return {
      data: data?.pages.flatMap((item) => item.channels),
      fetchNextPage,
      hasNextPage,
      isLoading,
    };
  };

  const useCreateChannel = () => {
    return useMutation({
      mutationFn: (payload: CreateChannelPayload) => chat.createChannel(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useDeleteChannel = () => {
    return useMutation({
      mutationFn: (payload: DeleteChannelPayload) => chat.deleteChannel(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useUpdateChannel = () => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: (payload: UpdateChannelPayload) => chat.updateChannel(payload),
      onSuccess(data, payload) {
        queryClient.invalidateQueries(chatKeys.messages(payload.channelId));
        queryClient.invalidateQueries(chatKeys.channel(payload.channelId));
        queryClient.invalidateQueries(chatKeys.channels());
      },
      onError() {},
    });
  };

  const useGetChannel = (payload: GetChannelPayload) => {
    return useQuery({
      queryKey: chatKeys.channel(payload.channelId),
      queryFn: () => chat.getChannel(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useAddMembers = () => {
    return useMutation({
      mutationFn: (payload: AddMembersPayload) => chat.addMembers(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useRemoveMembers = () => {
    return useMutation({
      mutationFn: (payload: RemoveMembersPayload) => chat.removeMembers(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useGetChannelMembers = (payload: GetChannelMembersPayload) => {
    return useQuery({
      queryKey: chatKeys.members(payload.channelId),
      queryFn: () => chat.getChannelMembers(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useBanMembers = () => {
    return useMutation({
      mutationFn: (payload: BanChannelMembersPayload) => chat.banMembers(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useUnBanMembers = () => {
    return useMutation({
      mutationFn: (payload: UnBanChannelMembersPayload) => chat.unBanMembers(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useBanMessage = () => {
    return useMutation({
      mutationFn: (payload: BanMessagePayload) => chat.banMessage(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useUnBanMessage = () => {
    return useMutation({
      mutationFn: (payload: UnBanMessagePayload) => chat.unbanMessage(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useJoinChannel = () => {
    return useMutation({
      mutationFn: (payload: JoinChannelPayload) => chat.joinChannel(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useLeaveChannel = () => {
    return useMutation({
      mutationFn: (payload: LeaveChannelPayload) => chat.leaveChannel(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useGetMessages = (payload: GetMessagesPayload) => {
    const { data, fetchNextPage, hasNextPage, isLoading, isFetching, isSuccess } = useInfiniteQuery({
      queryKey: chatKeys.messages(payload.channelId),
      queryFn: ({ pageParam }) => {
        return chat.getMessages({ ...payload, lastMessageId: pageParam, limit: 7 });
      },
      getNextPageParam(lastPage) {
        if (lastPage.hasNext) {
          return lastPage.messages.at(-1)?.id;
        }
      },
      onSuccess() {},
      onError() {},
      cacheTime: 0,
    });

    return {
      data: data?.pages.flatMap((message) => message.messages),
      fetchNextPage,
      hasNextPage,
      isLoading,
      isFetching,
      isSuccess,
    };
  };

  const useGetFileMessages = (payload: GetFileMessagesPayload) => {
    return useQuery({
      queryKey: chatKeys.fileMessages(payload.channelId, payload.messageId),
      queryFn: () => chat.getFileMessages(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useSendMessage = () => {
    const queryClient = useQueryClient();
    const { userInfo } = useSelector(selectAccount);

    const dispatchCustomEvent = (eventName: string, detail: any) => {
      const event = new CustomEvent(eventName, { detail });
      window.dispatchEvent(event);
    };

    return useMutation({
      mutationFn: (payload: SendMessagesPayload) => chat.sendMessage(payload),
      onSuccess(data, payload) {
        dispatchCustomEvent('chatMessageSent', { channelId: payload.channelId, text: payload.text });
      },
      onMutate: async ({ channelId, text, type, file, data }) => {
        // 진행 중인 메시지 fetch 취소
        await queryClient.cancelQueries(chatKeys.messages(channelId));

        // 이전 메시지 데이터 스냅샷
        const previousMessages = queryClient.getQueryData(chatKeys.messages(channelId));

        // 낙관적으로 새 메시지 추가
        const optimisticMessage: Message = {
          id: `temp-${Date.now()}`,
          channelId,
          userId: userInfo.user.id.toString(),
          username: userInfo.user.nickname,
          profileImageUrl: userInfo.user.get_profile || '',
          type,
          data,
          title: '',
          text: text || '',
          fileUrl: file ? URL.createObjectURL(file) : '',
          parentMessageId: '',
          createdAt: Date.now(),
        };

        if (type === MessageTypeEnum['Text']) {
          queryClient.setQueryData(chatKeys.messages(channelId), (old: any) => {
            if (!old?.pages?.[0]?.messages) return old;

            return {
              pageParams: old.pageParams,
              pages: [{ messages: [optimisticMessage] }, ...old.pages],
            };
          });
        }

        return { previousMessages };
      },
      onError: (err, variables, context) => {
        // 에러 발생 시 이전 상태로 롤백
        if (context?.previousMessages) {
          queryClient.setQueryData(chatKeys.messages(variables.channelId), context.previousMessages);
        }
      },
    });
  };

  const useMarkAsRead = () => {
    return useMutation({
      mutationFn: (payload: MarkAsReadPayload) => chat.markAsRead(payload),
      onSuccess() {},
      onError() {},
    });
  };

  const useGetNotice = (payload: GetMessagesPayload) => {
    return useQuery({
      queryKey: chatKeys.notice(payload.channelId),
      queryFn: () => chat.getMessages(payload),
      select(data) {
        return data.messages.find((message) => message.type === MessageTypeEnum['Custom']);
      },
    });
  };

  const useGetUnreadCount = (payload: GetUnreadCountPayload) => {
    return useQuery({
      queryKey: chatKeys.unreads(payload),
      queryFn: () => chat.getUnreadCount(payload),
      onError(err: any) {
        if (err.code === '1006') {
          window.location.reload();
        }
      },
    });
  };

  const useGetUnreadCountsByCampaign = (
    payload: GetUnreadCountPayload[],
    options?: UseQueryOptions<GetUnreadCountResponse>
  ) => {
    return useQueries(
      payload.map((payload) => ({
        queryKey: chatKeys.unreads(payload),
        queryFn: () => chat.getUnreadCount(payload),
        ...options,
      }))
    );
  };

  const useGetMessageUnreadCount = (payload: GetMessageUnreadCountPayload) => {
    return useQuery({
      queryKey: chatKeys.unread(payload.channel.id, payload.message.id),
      queryFn: () => chat.getMessageUnreadCount(payload),
    });
  };

  const useFreezeChannel = () => {
    return useMutation({
      mutationFn: (payload: FreezeChannelPayload) => chat.freezeChannel(payload),
    });
  };

  const useLogout = () => {
    return useMutation({
      mutationFn: chat.logout,
    });
  };

  return {
    chat,
    useCreateUser,
    useGetChannels,
    useCreateChannel,
    useDeleteChannel,
    useUpdateChannel,
    useGetChannel,
    useAddMembers,
    useRemoveMembers,
    useGetChannelMembers,
    useBanMembers,
    useUnBanMembers,
    useBanMessage,
    useUnBanMessage,
    useJoinChannel,
    useLeaveChannel,
    useGetMessages,
    useGetFileMessages,
    useSendMessage,
    useMarkAsRead,
    useGetNotice,
    useGetUnreadCount,
    useGetUnreadCountsByCampaign,
    useGetMessageUnreadCount,
    useFreezeChannel,
    useLogout,
    isLoggedIn: chat.isLoggedIn,
  } as const;
};
