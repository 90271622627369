import styled from 'styled-components';

import { color } from 'styles/assets';

interface Props {
  children: React.ReactNode;
  minHeight?: string;
  maxHeight?: string;
  height?: string;
  padding?: string;
  margin?: string;
  scrollable?: boolean;
  overflowY?: 'scroll' | 'visible' | 'hidden' | 'auto' | 'initial' | 'inherit';
  testid?: string;
  position?: 'auto' | 'relative';
  customStyle?: string;
}

const Block = ({
  children,
  height,
  padding,
  margin = '0',
  minHeight,
  scrollable = true,
  overflowY,
  testid,
  position,
  customStyle,
}: Props) => {
  return (
    <Container
      height={height}
      padding={padding}
      margin={margin}
      minHeight={minHeight}
      scrollable={scrollable}
      overflowY={overflowY}
      data-testid={testid}
      position={position}
      customStyle={customStyle}
    >
      {children}
    </Container>
  );
};

export default Block;

const Container = styled.div<{
  height?: string;
  padding?: string;
  margin?: string;
  minHeight?: string;
  maxHeight?: string;
  scrollable: boolean;
  overflowY?: 'scroll' | 'visible' | 'hidden' | 'auto' | 'initial' | 'inherit';
  position?: 'auto' | 'relative';
  customStyle?: string;
}>`
  width: 100%;
  background-color: ${color.white};
  padding: ${(props) => props.padding || '20px'};
  border-radius: 6px;
  position: relative;
  height: ${(props) => props.height || 'auto'};
  min-height: ${(props) => props.minHeight || 'auto'};
  max-height: ${(props) => props.maxHeight || 'auto'};
  overflow-y: ${(props) => (props.overflowY ? props.overflowY : props.scrollable ? 'scroll' : 'hidden')};
  margin: ${(props) => props.margin};
  ${(props) => props.position && `position: ${props.position};`}
  ${(props) => props.customStyle && props.customStyle}
`;
