import { useContext } from 'react';

import { AddChannelMembersResponse } from 'talkplus-sdk';

import { AppContext } from 'features/redux/context';

import {
  AddMembersPayload,
  AddMembersReturn,
  BanChannelMembersPayload,
  BanMessagePayload,
  ChatPlatformReturnType,
  CreateChannelPayload,
  CreateUserPayload,
  DeleteChannelPayload,
  EventCallback,
  FreezeChannelPayload,
  GetChannelMembersPayload,
  GetChannelPayload,
  GetChannelsPayload,
  GetFileMessagesPayload,
  GetMessageUnreadCountPayload,
  GetMessagesPayload,
  GetUnreadCountPayload,
  JoinChannelPayload,
  LeaveChannelPayload,
  MarkAsReadPayload,
  RemoveMembersPayload,
  SendMessagesPayload,
  UnBanChannelMembersPayload,
  UnBanMessagePayload,
  UpdateChannelPayload,
} from 'types/chat/remote';

export const useTalkPlus = (): ChatPlatformReturnType => {
  const { talkPlus } = useContext(AppContext);

  const isLoggedIn = talkPlus.isLoggedIn();

  const createUser = async (payload: CreateUserPayload) => {
    const { userId, username, profileImageUrl, ...restRequests } = payload;

    return await talkPlus.loginAnonymous({
      userId,
      username,
      profileImageUrl,
      ...restRequests,
    });
  };

  const createChannel = async (payload: CreateChannelPayload) => {
    return await talkPlus.createChannel({
      ...payload,
    });
  };

  const deleteChannel = async (payload: DeleteChannelPayload) => {
    const { channelId } = payload;

    return await talkPlus.deleteChannel({ channelId });
  };

  const getChannel = async (payload: GetChannelPayload) => {
    const { channelId } = payload;

    return await talkPlus.getChannel({
      channelId,
    });
  };

  const updateChannel = async (payload: UpdateChannelPayload) => {
    return await talkPlus.updateChannel({ ...payload });
  };

  const getChannels = async (payload: GetChannelsPayload) => {
    return await talkPlus.getChannels({ ...payload });
  };

  const addMembers = async (payload: AddMembersPayload) => {
    return await talkPlus.addChannelMembers({ ...payload });
  };

  const removeMembers = async (payload: RemoveMembersPayload) => {
    return await talkPlus.removeChannelMembers({ ...payload });
  };

  const getChannelMembers = async (payload: GetChannelMembersPayload) => {
    return await talkPlus.getChannelMembers({ ...payload });
  };

  const banMembers = async (payload: BanChannelMembersPayload) => {
    return await talkPlus.banUsersFromChannel({ ...payload });
  };

  const unBanMembers = async (payload: UnBanChannelMembersPayload) => {
    return await talkPlus.unbanUsersFromChannel({ ...payload });
  };

  const banMessage = async (payload: BanMessagePayload) => {
    return await talkPlus.muteChannelMembers({ ...payload });
  };

  const unbanMessage = async (payload: UnBanMessagePayload) => {
    return await talkPlus.unmuteChannelMembers({ ...payload });
  };

  const joinChannel = async (payload: JoinChannelPayload) => {
    return await talkPlus.joinChannel({ ...payload });
  };

  const leaveChannel = async (payload: LeaveChannelPayload) => {
    return await talkPlus.leaveChannel({ ...payload });
  };

  const getMessages = async (payload: GetMessagesPayload) => {
    return await talkPlus.getMessages({ ...payload });
  };

  const getFileMessages = async (payload: GetFileMessagesPayload) => {
    return await talkPlus.getFileMessages({ ...payload });
  };

  const sendMessage = async (payload: SendMessagesPayload) => {
    return await talkPlus.sendMessage({ ...payload });
  };

  const markAsRead = async (payload: MarkAsReadPayload) => {
    return await talkPlus.markChannelAsRead({ ...payload });
  };

  const getUnreadCount = async (payload: GetUnreadCountPayload) => {
    return await talkPlus.getUnreadCount({ ...payload });
  };

  const getMessageUnreadCount = async (payload: GetMessageUnreadCountPayload) => {
    return talkPlus.getMessageUnreadCount(payload);
  };

  const freezeChannel = async (payload: FreezeChannelPayload) => {
    return talkPlus.freezeChannel({ ...payload });
  };

  const subscribeToEvent = (eventName: string, callback: EventCallback) => {
    const eventHandler = (data: any) => {
      callback(data);
    };

    talkPlus.on(eventName, eventHandler);

    return () => talkPlus.off(eventName, eventHandler);
  };

  const logout = async () => {
    return talkPlus.logout();
  };

  return {
    isLoggedIn,
    createUser,
    createChannel,
    deleteChannel,
    getChannel,
    updateChannel,
    getChannels,
    addMembers,
    removeMembers,
    getChannelMembers,
    banMembers,
    unBanMembers,
    banMessage,
    unbanMessage,
    joinChannel,
    leaveChannel,
    getMessages,
    getFileMessages,
    sendMessage,
    markAsRead,
    getUnreadCount,
    getMessageUnreadCount,
    freezeChannel,
    subscribeToEvent,
    logout,
  };
};
