import { ReactComponent as Analytics } from 'assets/SVG/common/Analytics.svg';
import { ReactComponent as Block } from 'assets/SVG/common/Block.svg';
import { ReactComponent as Bulb } from 'assets/SVG/common/Bulb.svg';
import { ReactComponent as Calendar } from 'assets/SVG/common/Calendar.svg';
import { ReactComponent as Chat } from 'assets/SVG/common/Chat.svg';
import { ReactComponent as Check } from 'assets/SVG/common/Check.svg';
import { ReactComponent as CheckCircle } from 'assets/SVG/common/CheckCircle.svg';
import { ReactComponent as CheckCircleFilled } from 'assets/SVG/common/CheckCircleFilled.svg';
import { ReactComponent as Columns } from 'assets/SVG/common/Columns.svg';
import { ReactComponent as Credit } from 'assets/SVG/common/Credit.svg';
import { ReactComponent as CreditCard } from 'assets/SVG/common/CreditCard.svg';
import { ReactComponent as CrossCircle } from 'assets/SVG/common/CrossCircle.svg';
import { ReactComponent as Download } from 'assets/SVG/common/Download.svg';
import { ReactComponent as Edit } from 'assets/SVG/common/Edit.svg';
import { ReactComponent as ExitLeft } from 'assets/SVG/common/ExitLeft.svg';
import { ReactComponent as ExitRight } from 'assets/SVG/common/ExitRight.svg';
import { ReactComponent as ExternalLink } from 'assets/SVG/common/ExternalLink.svg';
import { ReactComponent as Eye } from 'assets/SVG/common/Eye.svg';
import { ReactComponent as Gift } from 'assets/SVG/common/Gift.svg';
import { ReactComponent as Global } from 'assets/SVG/common/Global.svg';
import { ReactComponent as GlobalDisabled } from 'assets/SVG/common/GlobalDisabled.svg';
import { ReactComponent as Handbag } from 'assets/SVG/common/Handbag.svg';
import { ReactComponent as Heart } from 'assets/SVG/common/Heart.svg';
import { ReactComponent as HeartFilled } from 'assets/SVG/common/HeartFilled.svg';
import { ReactComponent as Home } from 'assets/SVG/common/Home.svg';
import { ReactComponent as Infinite } from 'assets/SVG/common/Infinite.svg';
import { ReactComponent as InfoCircle } from 'assets/SVG/common/InfoCircle.svg';
import { ReactComponent as Jelly } from 'assets/SVG/common/Jelly.svg';
import { ReactComponent as JellyColored } from 'assets/SVG/common/JellyColored.svg';
import { ReactComponent as JellyDisabled } from 'assets/SVG/common/JellyDisabled.svg';
import { ReactComponent as Link } from 'assets/SVG/common/Link.svg';
import { ReactComponent as Loading } from 'assets/SVG/common/Loading.svg';
import { ReactComponent as Map } from 'assets/SVG/common/Map.svg';
import { ReactComponent as Notification } from 'assets/SVG/common/Notifications.svg';
import { ReactComponent as Paid } from 'assets/SVG/common/Paid.svg';
import { ReactComponent as PaidOff } from 'assets/SVG/common/PaidOff.svg';
import { ReactComponent as Picture } from 'assets/SVG/common/Picture.svg';
import { ReactComponent as Pin } from 'assets/SVG/common/Pin.svg';
import { ReactComponent as Preview } from 'assets/SVG/common/Preview.svg';
import { ReactComponent as QuestionCircle } from 'assets/SVG/common/QuestionCircle.svg';
import { ReactComponent as Requirements } from 'assets/SVG/common/Requirements.svg';
import { ReactComponent as Reset } from 'assets/SVG/common/Reset.svg';
import { ReactComponent as Search } from 'assets/SVG/common/Search.svg';
import { ReactComponent as Send } from 'assets/SVG/common/Send.svg';
import { ReactComponent as Setting } from 'assets/SVG/common/Setting.svg';
import { ReactComponent as SpeechBubble } from 'assets/SVG/common/SpeechBubble.svg';
import { ReactComponent as SpeechBubbleFilled } from 'assets/SVG/common/SpeechBubbleFilled.svg';
import { ReactComponent as SpeechBubbleWriting } from 'assets/SVG/common/SpeechBubbleWriting.svg';
import { ReactComponent as Star } from 'assets/SVG/common/Star.svg';
import { ReactComponent as StarFilled } from 'assets/SVG/common/StarFilled.svg';
import { ReactComponent as Table } from 'assets/SVG/common/Table.svg';
import { ReactComponent as Tag } from 'assets/SVG/common/Tag.svg';
import { ReactComponent as Template } from 'assets/SVG/common/Template.svg';
import { ReactComponent as Trash } from 'assets/SVG/common/Trash.svg';
import { ReactComponent as UGC } from 'assets/SVG/common/UGC.svg';
import { ReactComponent as UGCDisabled } from 'assets/SVG/common/UGCDisabled.svg';
import { ReactComponent as Upload } from 'assets/SVG/common/Upload.svg';
import { ReactComponent as UploadViolet39 } from 'assets/SVG/common/UploadViolet39.svg';
import { ReactComponent as UserAdd } from 'assets/SVG/common/UserAdd.svg';
import { ReactComponent as UserCheck } from 'assets/SVG/common/UserCheck.svg';
import { ReactComponent as UserRemove } from 'assets/SVG/common/UserRemove.svg';
import { ReactComponent as Users } from 'assets/SVG/common/Users.svg';
import { ReactComponent as WarningCircle } from 'assets/SVG/common/WarningCircle.svg';
import { ReactComponent as WarningCircleFilled } from 'assets/SVG/common/WarningCircleFilled.svg';
import { ReactComponent as WarningHex } from 'assets/SVG/common/WarningHex.svg';
import { ReactComponent as WarningTriangle } from 'assets/SVG/common/WarningTriangle.svg';
import { ReactComponent as WarningTriangleFilled } from 'assets/SVG/common/WarningTriangleFilled.svg';
import { ReactComponent as AndroidArrowDown } from 'assets/SVG/etc/AndroidArrowDown.svg';

import { ColorTypes, color } from 'styles/assets';

type IconType =
  | 'analytics'
  | 'android_arrow'
  | 'block'
  | 'bulb'
  | 'calendar'
  | 'check'
  | 'check_circle'
  | 'check_circle_filled'
  | 'columns'
  | 'credit'
  | 'credit_card'
  | 'cross_circle'
  | 'download'
  | 'edit'
  | 'exit_left'
  | 'exit_right'
  | 'eye'
  | 'global'
  | 'global_disabled'
  | 'gift'
  | 'handbag'
  | 'heart'
  | 'heart_filled'
  | 'home'
  | 'infinite'
  | 'info_circle'
  | 'jelly'
  | 'jelly_colored'
  | 'jelly_disabled'
  | 'link'
  | 'loading'
  | 'map'
  | 'notifications'
  | 'paid'
  | 'paid_off'
  | 'picture'
  | 'preview'
  | 'question_circle'
  | 'requirements'
  | 'reset'
  | 'search'
  | 'send'
  | 'setting'
  | 'speech_bubble'
  | 'speech_bubble_filled'
  | 'speech_bubble_writing'
  | 'star'
  | 'star_filled'
  | 'table'
  | 'tag'
  | 'template'
  | 'trash'
  | 'ugc'
  | 'ugc_disabled'
  | 'upload'
  | 'upload_violet39'
  | 'users'
  | 'warning_circle'
  | 'warning_circle_filled'
  | 'warning_hex'
  | 'warning_triangle'
  | 'warning_triangle_filled'
  | 'chat'
  | 'user_add'
  | 'user_check'
  | 'user_remove'
  | 'pin'
  | 'external_link';
interface Props {
  type: IconType;
  width?: number;
  height?: number;
  fill?: ColorTypes;
  testID?: string;
}

const Icon = ({ width = 24, height = 24, fill = 'black', type, testID }: Props) => {
  const defaultProps = {
    width,
    height,
    color: color[fill],
    'data-testid': testID || `icon_${type}`,
  };

  switch (type) {
    case 'analytics':
      return <Analytics {...defaultProps} />;
    case 'android_arrow':
      return <AndroidArrowDown {...defaultProps} />;
    case 'block':
      return <Block {...defaultProps} />;
    case 'bulb':
      return <Bulb {...defaultProps} />;
    case 'calendar':
      return <Calendar {...defaultProps} />;
    case 'check':
      return <Check {...defaultProps} />;
    case 'check_circle':
      return <CheckCircle {...defaultProps} />;
    case 'check_circle_filled':
      return <CheckCircleFilled {...defaultProps} />;
    case 'columns':
      return <Columns {...defaultProps} />;
    case 'credit':
      return <Credit {...defaultProps} />;
    case 'credit_card':
      return <CreditCard {...defaultProps} />;
    case 'cross_circle':
      return <CrossCircle {...defaultProps} />;
    case 'download':
      return <Download {...defaultProps} />;
    case 'edit':
      return <Edit {...defaultProps} />;
    case 'exit_left':
      return <ExitLeft {...defaultProps} />;
    case 'exit_right':
      return <ExitRight {...defaultProps} />;
    case 'eye':
      return <Eye {...defaultProps} />;
    case 'global':
      return <Global {...defaultProps} />;
    case 'global_disabled':
      return <GlobalDisabled {...defaultProps} />;
    case 'gift':
      return <Gift {...defaultProps} />;
    case 'handbag':
      return <Handbag {...defaultProps} />;
    case 'heart':
      return <Heart {...defaultProps} />;
    case 'heart_filled':
      return <HeartFilled {...defaultProps} />;
    case 'home':
      return <Home {...defaultProps} />;
    case 'infinite':
      return <Infinite {...defaultProps} />;
    case 'info_circle':
      return <InfoCircle {...defaultProps} />;
    case 'jelly':
      return <Jelly {...defaultProps} />;
    case 'jelly_colored':
      return <JellyColored {...defaultProps} />;
    case 'jelly_disabled':
      return <JellyDisabled {...defaultProps} />;
    case 'link':
      return <Link {...defaultProps} />;
    case 'loading':
      return <Loading {...defaultProps} />;
    case 'map':
      return <Map {...defaultProps} />;
    case 'notifications':
      return <Notification {...defaultProps} />;
    case 'paid':
      return <Paid {...defaultProps} />;
    case 'paid_off':
      return <PaidOff {...defaultProps} />;
    case 'picture':
      return <Picture {...defaultProps} />;
    case 'preview':
      return <Preview {...defaultProps} />;
    case 'question_circle':
      return <QuestionCircle {...defaultProps} />;
    case 'requirements':
      return <Requirements {...defaultProps} />;
    case 'reset':
      return <Reset {...defaultProps} />;
    case 'search':
      return <Search {...defaultProps} />;
    case 'send':
      return <Send {...defaultProps} />;
    case 'setting':
      return <Setting {...defaultProps} />;
    case 'speech_bubble':
      return <SpeechBubble {...defaultProps} />;
    case 'speech_bubble_filled':
      return <SpeechBubbleFilled {...defaultProps} />;
    case 'speech_bubble_writing':
      return <SpeechBubbleWriting {...defaultProps} />;
    case 'star':
      return <Star {...defaultProps} />;
    case 'star_filled':
      return <StarFilled {...defaultProps} />;
    case 'table':
      return <Table {...defaultProps} />;
    case 'tag':
      return <Tag {...defaultProps} />;
    case 'template':
      return <Template {...defaultProps} />;
    case 'trash':
      return <Trash {...defaultProps} />;
    case 'ugc':
      return <UGC {...defaultProps} />;
    case 'ugc_disabled':
      return <UGCDisabled {...defaultProps} />;
    case 'upload':
      return <Upload {...defaultProps} />;
    case 'upload_violet39':
      return <UploadViolet39 {...defaultProps} />;
    case 'users':
      return <Users {...defaultProps} />;
    case 'warning_circle':
      return <WarningCircle {...defaultProps} />;
    case 'warning_circle_filled':
      return <WarningCircleFilled {...defaultProps} />;
    case 'warning_hex':
      return <WarningHex {...defaultProps} />;
    case 'warning_triangle':
      return <WarningTriangle {...defaultProps} />;
    case 'warning_triangle_filled':
      return <WarningTriangleFilled {...defaultProps} />;
    case 'chat':
      return <Chat {...defaultProps} />;
    case 'user_add':
      return <UserAdd {...defaultProps} />;
    case 'user_check':
      return <UserCheck {...defaultProps} />;
    case 'user_remove':
      return <UserRemove {...defaultProps} />;
    case 'pin': {
      return <Pin {...defaultProps} />;
    }
    case 'external_link': {
      return <ExternalLink {...defaultProps} />;
    }
  }
};

export default Icon;
