import { Suspense } from 'react';

import {
  AdminOrderHistoryPage,
  AnalyticsPage,
  BannerDetailPage,
  BannerListPage,
  CampaignCreatePage,
  CampaignDetailPage,
  CampaignEditPage,
  CampaignGuidePage,
  CampaignListPage,
  ChatAdminPage,
  ChatPage,
  ClientChargeHistoryPage,
  ClientDetailPage,
  ClientListPage,
  ClientOrderHistoryPage,
  ClientSpendHistoryPage,
  DashboardMainPage,
  EditBannerPage,
  EditClientPage,
  EditNoticePage,
  EditUserPage,
  JellyChargeHistoryPage,
  JellySpendHistoryPage,
  LoginPage,
  MailAuthPage,
  MyJellyPage,
  NewBannerPage,
  NewClientPage,
  NewNoticePage,
  NewUserPage,
  NoticeDetailPage,
  NoticeListPage,
  NotificationsCreatePage,
  NotificationsDetailPage,
  NotificationsEditPage,
  NotificationsListPage,
  OrderHistoryPage,
  PackageSettingPage,
  PayoutRequestDetailPage,
  PayoutRequestListPage,
  SettingsMainPage,
  UserChargeHistoryPage,
  UserDetailPage,
  UserSpendHistoryPage,
  UsersMainPage,
} from 'pages';
import ChatBulkMessageDetailPage from 'pages/chat/bulkMessage/ChatBulkMessageDetailPage';
import ChatBulkMessageEditPage from 'pages/chat/bulkMessage/ChatBulkMessageEditPage';
import ChatBulkMessageListPage from 'pages/chat/bulkMessage/ChatBulkMessageListPage';
import { OverlayProvider } from 'providers/overlay-provider';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ErrorBoundary from 'router/ErrorBoundary';

import { userType } from 'utils/account/accessLevel';
import { canSendBulkMessage } from 'utils/campaign/access';

import PageLoading from 'components/common/loading/PageLoading';

import { selectAccount } from 'features/redux/selectors/accounts';

export default function Router() {
  const account = useSelector(selectAccount);
  const { userInfo, isLogin } = account;
  const { user } = userInfo;
  const bulkMessageUsable = canSendBulkMessage(user);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Suspense fallback={<PageLoading />}>
          <OverlayProvider>
            <Routes>
              {!isLogin ? (
                <>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/account/mail/:key" element={<MailAuthPage />} />
                  <Route path="*" element={<Navigate replace to="/login" />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<DashboardMainPage />} />
                  <Route path="/chat" element={<ChatPage />} />
                  {bulkMessageUsable && (
                    <>
                      <Route path="/chat/bulk-message" element={<ChatBulkMessageListPage />} />
                      <Route path="/chat/bulk-message/:id" element={<ChatBulkMessageDetailPage />} />
                      <Route path="/chat/bulk-message/:id/edit" element={<ChatBulkMessageEditPage />} />
                    </>
                  )}

                  <Route path="/list/:campaign" element={<CampaignListPage />} />
                  <Route path="/detail/:campaign/:id" element={<CampaignDetailPage />} />
                  <Route path="/detail/:campaign/:type/:id" element={<CampaignDetailPage />} />
                  <Route path="/add/:campaign/" element={<CampaignCreatePage />} />
                  <Route path="/add/:campaign/:type" element={<CampaignCreatePage />} />
                  <Route path="/edit/:campaign/:id" element={<CampaignEditPage />} />
                  <Route path="/edit/:campaign/:type/:id" element={<CampaignEditPage />} />

                  <Route path="/guide/campaign/:campaign" element={<CampaignGuidePage />} />

                  {userType(user) === 'super' ? (
                    <>
                      <Route path="/banner" element={<BannerListPage />} />
                      <Route path="/banner/:id" element={<BannerDetailPage />} />
                      <Route path="/banner/:id/edit" element={<EditBannerPage />} />
                      <Route path="/banner/new" element={<NewBannerPage />} />

                      {user.username === 'nurihaus_admin' && <Route path="/chat/admin" element={<ChatAdminPage />} />}

                      <Route path="/users" element={<UsersMainPage />} />
                      <Route path="/users/:id" element={<UserDetailPage />} />
                      <Route path="/users/:id/edit" element={<EditUserPage />} />
                      <Route path="/users/new" element={<NewUserPage />} />
                      <Route path="/users/:id/charge-history" element={<UserChargeHistoryPage />} />
                      <Route path="/users/:id/spend-history" element={<UserSpendHistoryPage />} />

                      <Route path="/client" element={<ClientListPage />} />
                      <Route path="/client/:id" element={<ClientDetailPage />} />
                      <Route path="/client/:id/:pointType/charge-history" element={<ClientChargeHistoryPage />} />
                      <Route path="/client/:id/:pointType/spend-history" element={<ClientSpendHistoryPage />} />
                      <Route path="/client/:id/:pointType/order-history" element={<ClientOrderHistoryPage />} />
                      <Route path="/client/:id/edit" element={<EditClientPage />} />
                      <Route path="/client/new" element={<NewClientPage />} />

                      <Route path="/point/:pointType" element={<MyJellyPage />} />
                      <Route path="/point/:pointType/admin-order-history" element={<AdminOrderHistoryPage />} />
                      <Route path="/point/:pointType/package-setting/:id" element={<PackageSettingPage />} />

                      <Route path="/payout" element={<PayoutRequestListPage />} />
                      <Route path="/payout/detail/:id" element={<PayoutRequestDetailPage />} />

                      <Route path="/notice" element={<NoticeListPage />} />
                      <Route path="/notice/:slug" element={<NoticeDetailPage />} />
                      <Route path="/notice/:slug/edit" element={<EditNoticePage />} />
                      <Route path="/notice/new" element={<NewNoticePage />} />

                      <Route path="/analytics" element={<AnalyticsPage />} />

                      <Route path="/notifications" element={<NotificationsListPage />} />
                      <Route path="/notifications/detail/:id" element={<NotificationsDetailPage />} />
                      <Route path="/notifications/create" element={<NotificationsCreatePage />} />
                      <Route path="/notifications/edit/:id" element={<NotificationsEditPage />} />
                    </>
                  ) : userType(user) === 'partner' ? (
                    <>
                      <Route path="/banner" element={<BannerListPage />} />
                      <Route path="/banner/:id" element={<BannerDetailPage />} />
                      <Route path="/banner/:id/edit" element={<EditBannerPage />} />
                      <Route path="/banner/new" element={<NewBannerPage />} />

                      <Route path="/users" element={<UsersMainPage />} />
                      <Route path="/users/:id" element={<UserDetailPage />} />
                      <Route path="/users/:id/charge-history" element={<UserChargeHistoryPage />} />
                      <Route path="/users/:id/spend-history" element={<UserSpendHistoryPage />} />

                      <Route path="/settings" element={<SettingsMainPage />} />

                      <Route path="/notifications" element={<NotificationsListPage />} />
                      <Route path="/notifications/detail/:id" element={<NotificationsDetailPage />} />
                      <Route path="/notifications/create" element={<NotificationsCreatePage />} />
                      <Route path="/notifications/edit/:id" element={<NotificationsEditPage />} />
                    </>
                  ) : userType(user) === 'brand' ? (
                    <>
                      <Route path="/point/:pointType" element={<MyJellyPage />} />
                      <Route path="/point/:pointType/charge-history" element={<JellyChargeHistoryPage />} />
                      <Route path="/point/:pointType/spend-history" element={<JellySpendHistoryPage />} />
                      <Route path="/point/:pointType/order-history" element={<OrderHistoryPage />} />

                      <Route path="/settings" element={<SettingsMainPage />} />
                    </>
                  ) : (
                    <></>
                  )}

                  <Route path="*" element={<Navigate replace to="/" />} />
                </>
              )}
            </Routes>
          </OverlayProvider>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
